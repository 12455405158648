import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import contentfulRichTextOptions from 'components/RichText/options/contentfulRichTextOptions';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

const HeroCopy = ({ subhead, headingKicker, heading, background, fullWidthContent, textColor }) => {
  const defaultTextColor = background !== 'White' ? 'white' : undefined;
  const hasHeadingKicker = headingKicker?.raw?.content?.length > 0;
  const isHeadingH2 =
    heading?.raw && JSON.parse(heading.raw)?.content.some((text) => text?.nodeType === 'heading-2');

  const headingKickerProps = {
    h1Size: 'overline',
    h2Size: 'overline',
    h3Size: 'overline',
    h4Size: 'overline',
    h5Size: 'overline',
    h6Size: 'overline',
    paragraphSize: 'overline',
    className: 'hero-copy-kicker',
    color:
      background === 'Custom' || background === 'Black'
        ? 'white'
        : background !== 'White'
        ? 'var(--grey-3)'
        : 'var(--grey-8)',
  };
  const headingProps = {
    h2Size: 'h2',
    h3Size: 'h1',
    h4Size: 'h1',
    h5Size: 'h1',
    h6Size: 'h1',
    paragraphSize: 'h1',
    overrideType: isHeadingH2 ? null : hasHeadingKicker ? 'p' : 'h1',
    className: 'hero-copy-title',
    color: hasHeadingKicker
      ? textColor || (background !== 'White' ? 'white' : 'Neutral 7')
      : textColor || defaultTextColor,
  };
  const subheadProps = {
    h1Size: 'p',
    h2Size: 'p',
    h3Size: 'p',
    h4Size: 'p',
    h5Size: 'p',
    h6Size: 'p',
    paragraphSize: 'p',
    overrideType: 'p',
    className: 'hero-copy-description',
    color: textColor || defaultTextColor,
  };

  return (
    <StyledDescription
      className="hero-copy"
      textColor={textColor || defaultTextColor}
      fullWidthContent={fullWidthContent}
    >
      {headingKicker &&
        renderRichText(headingKicker, contentfulRichTextOptions(headingKickerProps))}
      {heading?.raw && renderRichText(heading, contentfulRichTextOptions(headingProps))}
      {subhead?.raw && renderRichText(subhead, contentfulRichTextOptions(subheadProps))}
    </StyledDescription>
  );
};

export default HeroCopy;

HeroCopy.propTypes = {
  subhead: PropTypes.object,
  headingKicker: PropTypes.object,
  heading: PropTypes.object.isRequired,
  background: PropTypes.string,
  textColor: PropTypes.string,
};

HeroCopy.defaultProps = {
  heading: 'ServiceTitan Default Dynamic Hero Text',
};

const StyledDescription = styled.div`
  text-align: ${(props) => props.fullWidthContent && 'center'};
  p {
    color ${(props) => props.textColor || 'inherit'};

    a {
      color ${(props) => props.textColor || 'inherit'} !important;
      text-decoration: underline;
    }
  }
  ul {
    color ${(props) => props.textColor || 'inherit'};
  }
`;
