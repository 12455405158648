import styled from 'styled-components';
import { Col, Row } from 'components/Core/Grid';
import HyperLink from 'components/Core/HyperLink';

export const SectionRow = styled(Row)`
  margin-left: 0px !important;
  margin-right: 0px !important;

  @media (max-width: 575px) {
    flex-direction: ${(props) => (props.$reverseInMobile ? 'column-reverse' : 'column')};
  }
`;

export const SectionCol = styled(Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;

  li::marker {
    color: ${({ backgroundColor }) =>
      backgroundColor && backgroundColor !== 'White' && backgroundColor !== 'Transparent'
        ? 'white'
        : 'black'};
  }

  &.row-content-terms--col {
    padding-top: 120px;
    text-align: left !important;
    width: 100%;

    h2 {
      font-size: var(--font-size-35);
      padding-bottom: 40px;
    }

    h3 {
      font-size: var(--font-size-28);
      width: 100%;
      margin-top: 20px;
      padding-bottom: 20px;
    }

    h5 {
      font-size: var(--font-size-25);
      width: 100%;
      margin-top: 20px;
      padding-bottom: 20px;
    }

    h6 {
      font-size: var(--font-size-13);
      font-weight: normal;
      color: var(--critical-3);
      margin-top: 0;
      margin-bottom: 16px;
    }

    .blocks-paragraph {
      font-size: var(--font-size-16);
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 16px;
    }
  }

  @media (max-width: 991px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  @media (max-width: 575px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100%;
  }
`;

export const EmbeddedCta = styled(HyperLink)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BlockQuote = styled.p`
  font-weight: var(--font-weight-900);
  font-size: var(--font-size-25);
  line-height: var(--line-height-120);
  letter-spacing: var(--letter-spacing--0-02);
  color: var(--dark);
  text-align: left;
  max-width: 751px;
  padding: 41px 33px;
  box-shadow: 0px 15px 40px rgba(11, 26, 33, 0.1);
  background: var(--white);
  margin: 46px auto 61px;
  position: relative;

  &:before {
    content: ' ';
    width: 16px;
    height: 60px;
    background: var(--titan-blue-3);
    left: -16px;
    top: -16px;
    position: absolute;
  }

  &:after {
    content: ' ';
    width: 60px;
    height: 16px;
    background: var(--titan-blue-3);
    left: -16px;
    top: -16px;
    position: absolute;
  }

  @media (max-width: 767px) {
    font-size: var(--font-size-20);
    padding: 24px;
    max-width: 466px;
    margin-top: 46px;
    margin-bottom: 40px;

    &:before {
      width: 14px;
      height: 50px;
      left: -14px;
      top: -14px;
    }

    &:after {
      width: 50px;
      height: 14px;
      left: -14px;
      top: -14px;
    }
  }
`;
