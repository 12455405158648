/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Col, Row } from 'components/Core/Grid';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { flattenRichText } from 'utils/richTextUtils';
import Button from 'components/Core/Button';
import MarketoForm from 'components/Form/MarketoForm';
import WebsiteImage from 'components/WebsiteImage';
import Video from 'components/Core/Video';
import ContentfulEmbedCode from 'components/Contentful/ContentfulEmbedCode';
import Text from 'components/Core/Text';
import RichTextHyperLink from 'components/Core/HyperLink/RichTextHyperLink';
import { getRTInlineMarketingFormProps } from 'utils/marketo';
import { EmbeddedCta, BlockQuote } from './styles';
import { reduceContentValues } from 'utils/text';

export const options = (bgColor, leftAlignContent, slug) => {
  const Bold = ({ children }) => (
    <span className="bold">
      <b>{children}</b>
    </span>
  );
  const Italic = ({ children }) => (
    <span className="bold">
      <em>{children}</em>
    </span>
  );

  return {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
      [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node) => (
        <RichTextHyperLink href={node.data.uri}>
          {reduceContentValues(node?.content)}
        </RichTextHyperLink>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <Text
            type="p"
            className="blocks-paragraph"
            color={
              bgColor && bgColor !== 'White' && bgColor !== 'Transparent' ? 'white' : 'Neutral 6'
            }
            style={{ textAlign: leftAlignContent && 'left' }}
          >
            {children}
          </Text>
        );
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        return (
          <Text
            type="h1"
            className="blocks-heading-1"
            color={bgColor && bgColor !== 'White' && bgColor !== 'Transparent' ? 'white' : 'black'}
            style={{ textAlign: leftAlignContent && 'left' }}
          >
            {children}
          </Text>
        );
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return (
          <Text
            type="h2"
            className=" blocks-heading-2"
            color={bgColor && bgColor !== 'White' && bgColor !== 'Transparent' ? 'white' : 'black'}
            style={{ textAlign: leftAlignContent && 'left' }}
          >
            {children}
          </Text>
        );
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return (
          <Text
            className="blocks-heading-3"
            color={bgColor && bgColor !== 'White' && bgColor !== 'Transparent' ? 'white' : 'black'}
            size="h2"
            type="h3"
            style={{ textAlign: leftAlignContent && 'left' }}
          >
            {children}
          </Text>
        );
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        return (
          <Text
            className="blocks-heading-4"
            color={bgColor && bgColor !== 'White' && bgColor !== 'Transparent' ? 'white' : 'black'}
            size="h2"
            type="h4"
            style={{ textAlign: leftAlignContent && 'left' }}
          >
            {children}
          </Text>
        );
      },
      [BLOCKS.HEADING_5]: (node, children) => {
        return (
          <Text
            type="h5"
            className="blocks-heading-5"
            color={'var(--grey-3)'}
            style={{ textAlign: leftAlignContent && 'left' }}
          >
            {children}
          </Text>
        );
      },
      [BLOCKS.HEADING_6]: (node, children) => {
        return (
          <Text
            type="h6"
            className="blocks-heading-6"
            color={'var(--grey-3)'}
            style={{ textAlign: leftAlignContent && 'left' }}
          >
            {children}
          </Text>
        );
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        const type = node?.data?.target?.__typename;
        const component = { fields: node?.data?.target };

        const { alt, boxShadow, buttonLabel, embedCode, displayCode, url, quote, image } =
          flattenRichText(component.fields) || {};

        if (type === 'ContentfulInlineMarketingForm') {
          return (
            <MarketoForm
              dataPosition="2 Column"
              shadow={bgColor === 'White'}
              backgroundColor={bgColor !== 'White' ? 'var(--white)' : null}
              slug={slug}
              inlineMarketingForm={getRTInlineMarketingFormProps(component?.fields || component)}
            />
          );
        }

        if (type === 'ContentfulEmbedCode') {
          return (
            <ContentfulEmbedCode
              {...{
                embedCode,
                displayCode: displayCode || 'Inline',
              }}
            />
          );
        }

        if (type === 'ContentfulCallToAction') {
          return (
            <Row>
              <Col span={14} offset={5}>
                <EmbeddedCta
                  data-position="Body - 2 Column Layout CTA"
                  className="test-embedded-cta"
                  href={url}
                >
                  <Button width="100%">{buttonLabel}</Button>
                </EmbeddedCta>
              </Col>
            </Row>
          );
        }

        if (type === 'ContentfulBlockQuote') {
          return <BlockQuote>{quote}</BlockQuote>;
        }

        if (type === 'ContentfulImage') {
          const { contentType, url, details } = image?.file || {};

          if (contentType === 'video/mp4') {
            return (
              <Video
                boxShadow={boxShadow}
                type="preview-only"
                height="300px"
                width="100%"
                mainSrc={url}
                previewSrc={url}
                style={{ position: 'relative', right: '0px', top: '0px' }}
              />
            );
          }

          if (contentType?.includes('image')) {
            const altText = alt || image?.title;

            return (
              <WebsiteImage
                {...component.fields}
                alt={altText}
                style={{ width: '100%', height: 'auto', maxWidth: '100%', maxHeight: '400px' }}
              />
            );
          }
        }
      },
    },
  };
};
