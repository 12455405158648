import styled from 'styled-components';

const FilterTagsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  p {
    margin: 0 15px 0 0;
    white-space: nowrap;
  }

  > .MuiChip-root:first-of-type {
    margin-right: 25px;
  }

  .clear {
    color: var(--titan-blue-3);
    margin: 2px 0 0 5px;
    font-size: var(--font-size-p-sm);

    &:hover {
      cursor: pointer;
    }
  }
`;

export { FilterTagsContainer };
