import React from 'react';
import MarketoForm from 'components/Form/MarketoForm';
import WebsiteImage from 'components/WebsiteImage';
import Video from 'components/Core/Video';
import { SectionLayout } from 'components/Sections/SectionLayout';
import WistiaVideo from 'components/Core/WistiaVideo';
import { SectionCol, SectionRow } from '../styles';

const HeroFeaturedContent = ({ content, slug, featuredContentPosition, featuredContentSize }) => {
  let featuredContent;
  const featuredContentFull = featuredContentSize === 'Full Bleed';

  if (content) {
    const featuredContentNode = content[0];
    const typeName = featuredContentNode['__typename'];

    switch (typeName) {
      case 'ContentfulWistiaVideo':
        featuredContent = (
          <WistiaVideo
            thumbnailLoading="eager"
            dataPosition="Hero"
            {...featuredContentNode}
            fullWidth
            wrapperClassName="hero--wistia-video"
          />
        );
        break;
      case 'ContentfulImage':
        if (featuredContentNode?.image?.file?.url?.endsWith('mp4')) {
          featuredContent = (
            <Video
              width="100%"
              mainSrc={featuredContentNode?.image?.file?.url}
              previewSrc={featuredContentNode?.image?.file?.url}
              type="preview-only"
            />
          );
        } else {
          featuredContent = (
            <WebsiteImage
              loading="eager"
              style={{
                ...(featuredContentFull
                  ? { height: '100%', overflow: 'visible' }
                  : { width: '100%' }),
                marginTop: 0,
              }}
              {...featuredContentNode}
            />
          );
        }
        break;
      case 'ContentfulInlineMarketingForm':
        featuredContent = (
          <MarketoForm
            data-position="Hero"
            backgroundColor="var(--white)"
            slug={slug}
            inlineMarketingForm={featuredContentNode}
          />
        );
    }
  }

  if (featuredContentPosition === 'Bottom Overlay') {
    return (
      <SectionLayout contentBelowSection>
        <SectionRow justify="center" type="flex">
          <SectionCol xs={24} sm={20}>
            <div id="hero-featured-content" className="hero-featured-content--bottom">
              {featuredContent}
            </div>
          </SectionCol>
        </SectionRow>
      </SectionLayout>
    );
  }

  if (featuredContentPosition === 'Center Vertically') {
    return (
      <SectionCol
        className={`hero-featured-content--col${featuredContentFull ? '-full-bleed' : ''}`}
        lg={12}
        md={12}
        sm={12}
        xs={24}
      >
        <div className="hero-featured-content--center-vertically">
          <div className="hero-featured-content--content" id="hero-featured-content">
            {featuredContent}
          </div>
        </div>
      </SectionCol>
    );
  }

  return (
    <SectionCol
      className={`hero-featured-content--col${featuredContentFull ? '-full-bleed' : ''}`}
      lg={12}
      md={12}
      sm={12}
      xs={24}
    >
      <div id="hero-featured-content" className="hero-featured-content--side">
        {featuredContent}
      </div>
    </SectionCol>
  );
};

export default HeroFeaturedContent;
