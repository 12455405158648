import { renderRichText } from 'gatsby-source-contentful/rich-text';
import PropTypes from 'prop-types';
import React from 'react';
import { SectionLayout } from 'components/Sections/SectionLayout';
import { options as richTextOptions } from './richTextOptions';
import { SectionRow, SectionCol } from './styles';

//use these styles if there is no featured content
const noFeatureStyle = {
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
};

const Contentful2ColumnLayout = ({
  leftAlignContent,
  columnOne,
  columnTwo,
  backgroundColor,
  cornerImage,
  anchorId,
  pageType,
  slug,
  ...otherProps
}) => {
  if (leftAlignContent) noFeatureStyle.alignItems = 'start';

  const bgColor = backgroundColor;

  const options = richTextOptions(bgColor, leftAlignContent, slug);

  let reverseInMobile = false;
  if (columnTwo) {
    JSON.parse(columnOne?.raw)?.content.forEach((contentItem) => {
      if (contentItem?.data?.target?.sys?.contentType?.sys?.id === 'image') {
        reverseInMobile = true;
      }
    });
  }

  return (
    <SectionLayout
      backgroundColor={bgColor}
      backgroundImageTypeWithPosition={cornerImage}
      contentBelowSection={otherProps?.contentBelowSection}
      id={anchorId}
      data-testid="contentful-2-column-layout"
    >
      <SectionRow
        data-testid="section-row"
        type="flex"
        justify="center"
        align="middle"
        gutter={80}
        $reverseInMobile={reverseInMobile}
      >
        <SectionCol
          lg={columnTwo ? 12 : 24}
          md={columnTwo ? 12 : 24}
          sm={columnTwo ? 12 : 24}
          xs={24}
          style={!columnTwo && noFeatureStyle}
          className={pageType === 'terms' && 'row-content-terms--col'}
          backgroundColor={bgColor}
          data-testid="section-col-1"
        >
          {columnOne && renderRichText(columnOne, options)}
        </SectionCol>
        {columnTwo && (
          <SectionCol lg={12} md={12} sm={12} xs={24} data-testid="section-col-2">
            {columnTwo && renderRichText(columnTwo, options)}
          </SectionCol>
        )}
      </SectionRow>
    </SectionLayout>
  );
};

Contentful2ColumnLayout.propTypes = {
  columnOne: PropTypes.object.isRequired,
};

export default Contentful2ColumnLayout;
