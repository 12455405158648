import React from 'react';
import { twMerge } from 'tailwind-merge';
import WistiaVideo from 'components/Core/WistiaVideo';
import SimpleSocialProof from 'components/Sections/SocialProofSection/Simple';
import getImage from 'components/WebsiteImage/utils/getImage';
import { useCheckHeroDark, colorTypeHelper } from 'utils/colorUtility';
import HeroCopy from './HeroCopy';
import HeroFeaturedContent from './HeroFeaturedContent';
import HeroContextualCTA from './HeroContextualCTA';

import { SectionCol, SectionRow, StyledSectionLayout, DemoAndSeePreviewContainer } from './styles';

const ContentfulDynamicHero = ({
  anchorId,
  background,
  backgroundImage,
  className,
  contentLeftAligned,
  contextualCta,
  customInlineForm,
  featuredContent,
  featuredContentPosition,
  featuredContentSize,
  heading1,
  headingKicker1,
  images,
  inlineMarketingForm,
  kickerStyle,
  popupVideo,
  reverseMobileOrder,
  seo,
  socialProof,
  subhead,
  textColor,
  ...otherProps
}) => {
  const backgroundImageToRender = getImage(backgroundImage);
  const { pageUrl, showBreadcrumbs } = seo || {};
  let featuredContentVariation;
  let fullWidthContent;
  const centerVertically = featuredContentPosition === 'Center Vertically';

  const heroBackgroundImage = backgroundImageToRender?.gatsbyImageData
    ? backgroundImageToRender
    : backgroundImageToRender?.file?.url || backgroundImageToRender;

  const isBackgroundWhite =
    textColor === 'White'
      ? 'Black'
      : textColor === 'Black'
      ? 'White'
      : backgroundImage
      ? useCheckHeroDark({ backgroundImage, background })
        ? 'Black'
        : 'White'
      : background;

  const backgroundColor = colorTypeHelper[background];
  const showInlineForm = !(contextualCta && !customInlineForm);

  if (contentLeftAligned) {
    fullWidthContent = false;
  } else if (
    !featuredContent ||
    (featuredContent && featuredContentPosition === 'Bottom Overlay')
  ) {
    fullWidthContent = true;
  }

  if (featuredContent) {
    featuredContentVariation = featuredContentPosition !== 'Bottom Overlay' ? 'side' : 'bottom';
  }

  const contextualCtaNode = contextualCta ? contextualCta[0] : null;

  const fullWidthStyles = {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  };
  const centerVerticallyStyles = {
    height: 'auto',
  };

  const HeroContent = (
    <>
      <HeroCopy
        subhead={subhead}
        heading={heading1}
        headingKicker={headingKicker1}
        background={isBackgroundWhite}
        featuredContentPosition={featuredContentPosition}
        fullWidthContent={fullWidthContent}
        textColor={textColor}
      />
      {!showInlineForm && popupVideo && (
        <DemoAndSeePreviewContainer
          isInline={contextualCtaNode?.__typename === 'ContentfulCallToAction'}
        >
          <HeroContextualCTA
            content={contextualCta}
            inlineMarketingForm={inlineMarketingForm}
            contentLeftAligned={contentLeftAligned}
            fullWidth={fullWidthContent}
          />
          <WistiaVideo
            dataPosition="Hero"
            {...{ ...popupVideo, label: popupVideo.label || 'Play Video' }}
          />
        </DemoAndSeePreviewContainer>
      )}
      {!showInlineForm && !popupVideo && (
        <HeroContextualCTA
          content={contextualCta}
          inlineMarketingForm={inlineMarketingForm}
          contentLeftAligned={contentLeftAligned}
          fullWidth={fullWidthContent}
        />
      )}
      {customInlineForm}
      {socialProof && (
        <SimpleSocialProof theme={isBackgroundWhite === 'White' ? 'dark' : 'light'} />
      )}
      {showInlineForm && popupVideo && (
        <WistiaVideo
          dataPosition="Hero"
          {...{
            ...popupVideo,
            label: popupVideo.label || 'Play Video',
            labelProps: { theme: isBackgroundWhite === 'White' ? 'black' : 'light' },
          }}
        />
      )}
    </>
  );

  return (
    <>
      <StyledSectionLayout
        bgColor={backgroundColor}
        backgroundImg={heroBackgroundImage}
        $contentLeftAligned={contentLeftAligned}
        $reverseMobileOrder={reverseMobileOrder}
        $showBreadcrumbs={showBreadcrumbs}
        $kickerStyle={kickerStyle}
        innerContainerStyles={
          featuredContentVariation === 'bottom' &&
          'dynamic-hero-bottom-featured-content--inner-container'
        }
        id={anchorId}
        className={twMerge('hero', className)}
        type="hero"
        {...otherProps}
      >
        <SectionRow
          type="flex"
          justify={contentLeftAligned ? 'start' : 'center'}
          gutter={80}
          $showBreadcrumbs={showBreadcrumbs}
          className={`test-${showInlineForm ? 'inline' : 'modal'}-hero`}
          {...(centerVertically && { align: 'middle' })}
        >
          <SectionCol
            lg={fullWidthContent ? 24 : 12}
            md={fullWidthContent ? 24 : 12}
            sm={fullWidthContent ? 24 : 12}
            xs={24}
            $contentLeftAligned={contentLeftAligned}
            style={
              (fullWidthContent && fullWidthStyles) ||
              (centerVertically && centerVerticallyStyles) ||
              null
            }
          >
            {centerVertically ? (
              <div className="hero-featured-content--center-vertically">
                <div className="hero-featured-content--content">{HeroContent}</div>
              </div>
            ) : (
              HeroContent
            )}
          </SectionCol>
          {(featuredContentVariation === 'side' || !featuredContent) && (
            <HeroFeaturedContent
              featuredContentPosition={featuredContentPosition}
              featuredContentSize={featuredContentSize}
              content={featuredContent}
              contentLeftAligned={contentLeftAligned}
              slug={pageUrl}
              hero
            />
          )}
        </SectionRow>
      </StyledSectionLayout>
      {featuredContentVariation === 'bottom' && (
        <HeroFeaturedContent
          featuredContentPosition={featuredContentPosition}
          content={featuredContent}
          contentLeftAligned={contentLeftAligned}
          slug={pageUrl}
        />
      )}
    </>
  );
};
export default ContentfulDynamicHero;
