import React from 'react';
import Tag from 'components/Core/Tag';
import { FilterTagsContainer } from './styles';

const FilterTags = ({ clearable, tags, title, setSelectedTags, selectedTags }) => (
  <FilterTagsContainer>
    {title && <p>{title}:</p>}
    {tags
      ?.filter((tag) => tag)
      ?.map((tag, index) => (
        <Tag
          className={selectedTags.includes(tag) ? 'selected' : ''}
          onClick={() => setSelectedTags(tag)}
          key={index}
        >
          {tag}
        </Tag>
      ))}
    {clearable && (
      <p className="clear" onClick={() => setSelectedTags(null)}>
        Clear
      </p>
    )}
  </FilterTagsContainer>
);

export default FilterTags;
